import Carousel from "../Content-carousel";
import Form from "../Form";

function Home() {
    return (
        <>
        <Carousel />
        <Form />
        </>
    );
}

export default Home;