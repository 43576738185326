import React from "react";
import "../css/index.css";

export default function SideScroll(){
    return (
        <>
        <div className="flex flex-col">
            <div>
                <ul className="w-20 h-40 overflow-hidden overflow-y-scroll">
                    <li className="mb-1">6te9</li>
                    <li className="mb-1">6te9</li>
                    <li className="mb-1">6te9</li>
                    <li className="mb-1">6te9</li>
                    <li className="mb-1">6te9</li>
                    <li className="mb-1">6te9</li>
                    <li className="mb-1">6te9</li>
                </ul>
            </div>
        </div>

        </>
    )
}